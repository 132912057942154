.home {
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding: 25px 0;
}

/* hero section */

.home .hero {
  width: calc(100vw - 200px);
  height: 850px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(11, 1fr);
  gap: 25px;
}

.hero .box {
  border-radius: 15px;
  background: #97b1ab;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero .box.a {
  background: #d6cdd6;
  grid-column: 1 / 4;
  grid-row: 1 / 10;
  overflow: hidden;
}

.hero .box.e {
  background: #b6c8c4;
  grid-column: 3 / 5;
  grid-row: 10 / 24;
}

.hero .box.f {
  background: #94aac9;
  grid-column: 5 / 15;
  grid-row: 10 / 24;
}

.hero .box.a {
  flex-direction: column;
  align-items: normal;
  padding: 0 75px;
}

.hero .box.a h1 {
  font-size: 44px;
  font-weight: 400;
  margin: 0;
}

.hero .box.a h1 span {
  font-weight: 700;
}

.hero .box.a p {
  color: rgba(0, 0, 0, 0.7);
  line-height: 27px;
}

.hero .box.a button {
  width: fit-content;
  background: #2865be;
  color: white;
  cursor: pointer;
  border: none;
  padding: 12px 23px;
  font-weight: 500;
  border-radius: 25px;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}

.hero .box.a button:hover {
  background: #998099;
}

.hero .box.a i {
  background: white;
  color: #417ed7;
  border-radius: 50%;
  margin-right: 12px;
  font-size: 20px;
  transition: 0.5s;
}

.hero .box.a button:hover i {
  color: #998099;
}

.hero .box.b {
  background: #9bbbea;
  grid-column: 4 / 7;
  grid-row: 1 / 10;
  overflow: hidden;
  padding: 35px 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.hero .box.b h1 {
  width: 100%;
  height: fit-content;
  color: white;
  font-size: 24px;
  margin: 0;
  margin-bottom: 15px;
  /* text-align: center; */
}

.hero .box.b img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin-bottom: -50px;
}

.hero .box.c {
  grid-column: 7 / 15;
  grid-row: 1 / 10;
  background: #f1ebdf;
  flex-direction: column;
  padding: 25px 35px;
  padding-top: 33px;
}

.hero .box.c h1 {
  font-size: 22px;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}

.hero .box.c img {
  width: 100%;
  height: 50%;
  object-fit: contain;
  border: 1px solid white;
  border-radius: 25px;
  padding-top: 15px;
}

.hero .box.c img:last-of-type {
  margin-top: 15px;
}

.hero .box.c img.string_shape {
  padding: 0;
  padding-left: 20%;
  border: none;
  border-radius: none;
}

.hero .box.c img.string_shape {
  position: absolute;
  width: 100%;
  height: 100%;
  left: -88%;
}

.hero .box.d {
  background: #97b1ab;
  background: white;
  grid-column: 1 / 3;
  grid-row: 10 / 24;
  flex-direction: column;
  /* padding: 7px; */
  justify-content: space-between;
  overflow: hidden;
  /* border: 1px solid rgba(0, 0, 0, 0.24); */
}

.hero .box.d img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* background: #daeaeb; */
}

.hero .box.d h1 {
  margin: 0;
  padding: 0;
  margin-top: 20px;
  color: white;
  font-weight: 500;
  font-size: 28px;
  margin-bottom: 2px;
  text-align: start;
}

.hero .box.d span {
  width: 100%;
  color: white;
  font-size: 14px;
  font-weight: 700;
  text-align: end;
}

.hero .box.e {
  overflow: hidden;
  padding: 30px;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
  background: #ccd9d6;
}

.hero .box.e .content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.hero .box.e p {
  width: 99%;
  font-size: 20px;
  text-align: center;
  font-weight: 300;
  font-size: 18px;
}

.hero .box.e p:last-of-type {
  margin-top: 35px;
}

.hero .box.e span {
  background: white;
  padding: 5px;
  display: flex;
  align-items: center;
  border-radius: 30px;
  color: #51aaa2;
}

.hero .box.e span i {
  background: #51aaa2;
  color: white;
  padding: 12px;
  font-size: 24px;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 15px;
  transition: 0.5s;
}

.hero .box.e span:hover i {
  background: #2865be;
}

.hero .box.e a {
  letter-spacing: 2px;
  margin-left: 3px;
  text-decoration: none;
  padding-right: 15px;
  font-weight: 800;
  padding-left: 2px;
  color: #51aaa2;
  text-align: center;
}

.hero .box.e a.not_number {
  font-size: 16px;
  letter-spacing: normal;
  margin-top: 25px;
  margin-bottom: 11px;
  color: rgba(0, 0, 0, 0.44);
  transition: 0.5s;
}

.hero .box.e a.not_number:hover {
  color: #51aaa2;
}

.hero .box.e a.not_number i {
  margin-right: 4px;
  font-size: 20px;
}

.hero .box.e .hours {
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  margin-top: 3px;
  color: rgba(0, 0, 0, 0.44);
}

.hero .box.e .hours i {
  color: rgba(0, 0, 0, 0.64);
  margin-right: 3px;
}

.hero .box.f {
  padding: 20px;
  overflow: hidden;
  display: flex;
  gap: 20px;
}

.hero .box.f img {
  width: 50%;
  height: 100%;
  object-fit: contain;
  background: white;
  border-radius: 20px;
}

/* parter logos */

.partner_logos {
  width: 1350px;
  overflow: hidden;
  padding: 100px 0;
  padding-top: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
  position: relative;
}

.partner_logos span {
  font-size: 14px;
  font-weight: 700;
  color: #417ed7;
  position: absolute;
  top: 95px;
  left: 50%;
  transform: translateX(-50%);
}

.partner_logos .partner {
  width: 100px;
  object-fit: contain;
}

.partner_logos .partner:nth-child(4) {
  color: rgba(0, 0, 0, 0.05);
  padding: 5px;
}

/* message */

.home .message {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
}

.home .message > span {
  width: 60%;
  color: #2865be;
  font-weight: 700;
  font-size: 16px;
}

.home .message p {
  width: 60%;
  font-size: 52px;
  margin: 0;
  margin-top: 15px;
  position: relative;
}

.home .message p span {
  color: rgba(0, 0, 0, 0.24);
}

.home .message img {
  width: 100px;
  position: absolute;
  bottom: -125px;
  right: -175px;
  transform: rotate(-135deg);
  animation: spring_swing 4s linear infinite;
}

@keyframes spring_swing {
  0%,
  100% {
    margin-right: 0;
  }
  50% {
    margin-right: 40px;
  }
}

/* different experiences section  */

.home .experiences {
  width: 1350px;
  height: 677px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  flex-wrap: wrap;
  margin-top: 75px;
  overflow: hidden;
}

.home .experiences .item {
  width: calc(50% - 13px);
  height: 25%;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}

.home .experiences .item > img {
  position: absolute;
  inset: -15px;
  object-fit: cover;
}

.home .experiences .item.a {
  height: 350px;
  background-image: linear-gradient(to bottom right, #283a2d, #e1e7e5);
}

.home .experiences .item.a .content {
  position: absolute;
  color: white;
  padding: 0 55px;
}

.home .experiences .item.a .content h1 {
  font-size: 54px;
  margin-bottom: 30px;
  margin-top: 25px;
}

.home .experiences .item.a .content ul {
  padding: 0;
  list-style: none;
}

.home .experiences .item.a .content li {
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 28px;
  display: flex;
}

.home .experiences .item.a .content li i {
  padding-right: 9px;
  padding-top: 5px;
}

.home .experiences .item.b {
  height: 300px;
  background: #d6cdd6;
  padding: 35px;
}

.home .experiences .item.b .reviews {
  width: 100%;
  overflow: hidden;
}

.home .experiences .item.b .reviews_wrapper {
  width: 500%;
  display: flex;
}

.home .experiences .item.b .reviews_wrapper .r {
  width: 20%;
}

.home .experiences .item.b i.bxs-quote-right {
  color: white;
  transform: rotateX(180deg) rotate(180deg);
  font-size: 44px;
}

.home .experiences .item.b p {
  height: 65px;
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  display: flex;
}

.home .experiences .item.b .identity {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 55px;
}

.home .experiences .item.b .profile {
  display: flex;
  align-items: center;
  gap: 15px;
}

.home .experiences .item.b .profile img {
  width: 50px;
  height: 50px;
  background: white;
  border-radius: 50%;
}

.home .experiences .item.b .profile span {
  font-weight: 600;
  display: block;
  color: black;
  padding-top: 5px;
}

.home .experiences .item.b .identity .arrow i {
  border: 1px solid white;
  border-radius: 50%;
  font-size: 18px;
  padding: 12px;
  cursor: pointer;
  transition: 0.6s;
}

.home .experiences .item.b .reviews.loading .identity .arrow i {
  background: rgba(255, 255, 255, 0.7);
}

.home .experiences .item.b .identity .arrow i:first-of-type {
  margin-right: 11px;
}

.home .experiences .item.b .identity .arrow i:hover {
  background: white;
}

.home .experiences .item.b .stars {
  display: flex;
  gap: 3px;
  padding-top: 7px;
  padding-bottom: 8px;
}

.home .experiences .item.b .stars i {
  border: none;
  background: none;
  color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.home .experiences .item.c {
  width: 250px;
  height: 125px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: #ccd9d6;
  gap: 12px;
}

.home .experiences .item.c > span {
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1px;
}

.home .experiences .item.c .rating {
  display: flex;
  align-items: center;
  font-size: 36px;
}

.home .experiences .item.c .rating i {
  color: #51aaa2;
}

.home .experiences .item.c .rating span {
  font-weight: 800;
  margin-left: 8px;
}

.home .experiences .item.d {
  height: 350px;
  padding: 0;
  position: relative;
}

.home .experiences .item.d .interior {
  width: 100%;
  height: 100%;
}

.home .experiences .item.d .interior_wrapper {
  width: 300%;
  height: 100%;
  display: flex;
  position: relative;
  transition: 0.5s;
}

.home .experiences .item.d img {
  width: 33.3333%;
  height: 100%;
  object-fit: cover;
}

.home .experiences .item.d img:nth-child(5),
.home .experiences .item.d img:nth-child(4) {
  background-image: linear-gradient(50deg, #bb9e7c, #2c3533);
  object-fit: contain;
}

.home .experiences .item.d img:nth-child(4) {
  background-image: linear-gradient(50deg, #b0a899, #18100b);
}

.home .experiences .item.d .dots {
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 9px;
}

.home .experiences .item.d .dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.67);
  cursor: pointer;
  transition: 0.5s;
  position: relative;
}

.home .experiences .item.d .dot.active {
  width: 13px;
  height: 13px;
  box-shadow: white 0px 8px 24px, white 0px 16px 56px, white 0px 24px 80px;
  background: white;
  bottom: 3px;
}

.home .experiences .item.e {
  width: 450px;
  height: 150px;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.03);
  padding: 0 37px;
}

.home .experiences .item.e .logo_small {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background: white;
  position: relative;
  margin-right: 18px;
  transition: 0.5s;
  overflow: hidden;
  top: -5px;
}

.home .experiences .item.e img {
  width: 100%;
  position: absolute;
  inset: 0;
  z-index: 0;
}

.home .experiences .item.e img:first-of-type {
  width: 100%;
  position: absolute;
  z-index: 1;
  inset: 50%;
  transform: translate(-50%, -50%);
  padding: 11px;
}

.home .experiences .item.e img:last-of-type {
  animation: rotate 21s linear infinite;
  top: 2px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.home .experiences .item.e span {
  font-size: 12px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.6);
}

.home .experiences .item.e p {
  width: 95%;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  margin: 7px 0;
}

.home .experiences .item.e button {
  width: fit-content;
  border: none;
  background: none;
  color: #998099;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 5px;
  position: relative;
  padding: 3px;
  padding-left: 25px;
  transition: 0.5s;
  cursor: pointer;
}

.home .experiences .item.e button i {
  margin-right: 3px;
  font-size: 20px;
  position: absolute;
  transition: 0.5s;
  right: calc(100% - 25px);
}

.home .experiences .item.e button:hover {
  padding: 3px;
}

.home .experiences .item.e button:hover i {
  position: absolute;
  right: -25px;
}

/* scrolling text */

.scrolling_text {
  width: 100vw;
  margin: 75px 0;
  margin-bottom: 85px;
  overflow-x: hidden;
  white-space: nowrap;
}

.scrolling_text .wrapper {
  width: fit-content;
  display: flex;
  white-space: nowrap;
  animation: slide-left-right 44s linear infinite;
}

.scrolling_text .wrapper:hover {
  animation-play-state: paused;
}

.scrolling_text .text {
  width: fit-content;
  font-size: 109px;
  font-weight: 800;
  color: #d5dae5;
  letter-spacing: 2px;
  overflow: hidden;
  display: block;
  transition: 0.4s;
  padding-right: 25px;
}

.scrolling_text .text .asterisk {
  padding: 0 45px;
  font-size: 74px;
  position: relative;
  bottom: 15px;
  display: inline-block;
}

@keyframes slide-left-right {
  0% {
    transform: translateX(100vw);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* our services */

.our_services {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.our_services .sub_heading {
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #2865be;
}

.our_services h1 {
  font-size: 44px;
  width: 50%;
  text-align: center;
  margin: 0;
  margin-top: 15px;
  font-weight: 500;
}

.our_services h1 b {
  font-weight: 800;
}

.our_services .services {
  width: 1350px;
  height: 325px;
  display: flex;
  justify-content: center;
  gap: 25px;
  margin-top: 50px;
}

.our_services .services .service {
  width: 350px;
  background: #ccd9d6;
  border-radius: 20px;
  display: flex;
  align-items: start;
  flex-direction: column;
  padding: 36px 30px;
  transition: 0.5s;
  box-shadow: white 10px 10px;
  position: relative;
}

.our_services .services .service > i {
  font-size: 54px;
  color: #7ea3d8;
  margin-left: -5px;
}

.our_services .services .service > img {
  width: 55px;
}

.our_services .services .service > img.hair0 {
  margin-bottom: -15px;
}

.our_services .services .service h2 {
  font-size: 18px;
  margin-top: 25px;
  transition: 0.5s;
  line-height: 25px;
  margin-bottom: 11px;
}

.our_services .services .service span {
  font-size: 16px;
  font-weight: 400;
  padding-right: 15px;
  line-height: 25px;
  color: rgba(0, 0, 0, 0.6);
}

.our_services .services .service button {
  background: transparent;
  border: none;
  margin-top: 25px;
  font-size: 15px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.9);
  padding: 5px 25px;
  padding-left: 22px;
  position: absolute;
  bottom: 30px;
  transition: 0.5s;
}

.our_services .services .service button i {
  margin-right: 5px;
  position: absolute;
  left: 0;
  font-size: 18px;
  transition: 0.5s;
}

.our_services .services .service button:hover {
  padding-left: 0;
  color: #2865be;
  cursor: pointer;
}

.our_services .services .service button:hover i {
  left: calc(100% - 20px);
}

.our_services .services .service:hover {
  background: white;
  box-shadow: #d6cdd6 10px 10px;
}

.our_services .services .service:hover h2 {
  color: #2865be;
  cursor: pointer;
}

/* tests */

.tests {
  width: calc(100vw - 200px);
  height: 800px;
  margin-top: 135px;
  display: flex;
  gap: 25px;
}

.tests .doctor {
  width: 50%;
  height: 100%;
  background: grey;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}

.tests .doctor img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.tests .doctor .doctor_award {
  position: absolute;
  bottom: 15px;
  left: 0;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.97);
  padding: 15px 0;
  padding-left: 5%;
  border-radius: 50px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.tests .doctor .doctor_award .content {
  padding: 0;
}

.tests .doctor .doctor_award i:first-of-type {
  font-size: 54px;
  color: rgb(148, 170, 201);
  margin-right: 10px;
}

.tests .doctor .doctor_award h3 {
  width: fit-content;
  font-size: 15px;
  font-weight: 500;
  padding: 0;
  margin: 0;
}

.tests .doctor .doctor_award span {
  margin-top: 3px;
  margin-bottom: 2px;
  display: inline-block;
  font-weight: 700;
}

.tests .doctor .doctor_award p {
  padding: 0;
  margin: 0;
  font-weight: 300;
  font-size: 14px;
  width: 85%;
  margin: 0;
}

.tests .doctor .doctor_award i:last-of-type {
  margin-right: 15px;
  background: #9bbbea;
  border-radius: 50%;
  padding: 17px;
  transition: 0.5s;
  cursor: pointer;
}

.tests .doctor .doctor_award i:last-of-type:hover {
  background: #94aac9;
  color: white;
}

.tests > .content {
  width: 50%;
  background: #e1e6eb;
  display: flex;
  flex-direction: column;
  padding: 85px 65px;
  border-radius: 20px;
  position: relative;
}

.tests > .content .sub_heading {
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #417ed7;
}

.tests > .content h2 {
  font-size: 44px;
  font-weight: 400;
  margin: 0;
  margin-top: 20px;
}

.tests .tests_slider {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 20px;
  margin-top: 50px;
}

.tests .tests_slider .wrapper {
  width: 400%;
  height: 100%;
  display: flex;
  position: relative;
  transition: 0.5s;
  left: 0;
}

.tests .tests_slider .item {
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  padding: 3% 2%;
  position: relative;
}

.tests .tests_slider img {
  width: 165px;
  position: absolute;
  top: -5px;
  right: -5px;
  transform: rotate(-45deg);
  transition: 0.3s;
}

.tests .tests_slider .content > span,
.tests .tests_slider .item > span {
  width: fit-content;
  border: 1px solid black;
  padding: 6px 11px;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.5s;
  border: 1px soild #417ed7;
}

.tests .tests_slider .content > span:hover,
.tests .tests_slider .item > span:hover {
  color: #417ed7;
  border: 1px solid #417ed7;
}

.tests .tests_slider .item > span {
  margin-left: 13px;
}

.tests .tests_slider ul {
  width: 75%;
  height: 75%;
  padding-left: 0;
  list-style: none;
  padding-left: 5px;
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  padding-top: 15px;
  overflow-y: scroll;
}

.tests .tests_slider ul li {
  width: fit-content;
  padding: 2px 3px;
  padding-bottom: 5px;
  gap: 7px;
  position: relative;
  transition: 0.5s;
  left: 33px;
}

.tests .tests_slider ul li i {
  font-size: 24px;
  position: absolute;
  left: -30px;
  transition: 0.5s;
  top: 0;
}

.tests .tests_slider ul li:hover {
  left: 13px;
  cursor: pointer;
  color: #417ed7;
}

.tests .tests_slider ul li:hover i {
  left: 100%;
}

.tests .tests_slider button {
  width: fit-content;
  border: 1px solid black;
  background: none;
  margin-left: auto;
  margin-right: 30px;
  border-radius: 25px;
  padding: 7px 13px;
  cursor: pointer;
  transition: 0.5s;
  position: absolute;
  bottom: 35px;
  right: 35px;
}

.tests .tests_slider button:hover {
  color: #417ed7;
  border: 1px solid #417ed7;
}

.tests .tests_slider .item:hover img {
  top: -20px;
  right: -20px;
}

.tests .dots {
  position: absolute;
  bottom: 45px;
  display: flex;
  gap: 7px;
  left: 50%;
  transform: translateX(-50%);
}

.tests .dots .dot {
  width: 7px;
  height: 7px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  cursor: pointer;
  transition: 0.5s;
}

.tests .dots .dot.active {
  width: 13px;
  height: 13px;
  background: black;
  position: relative;
  bottom: 5px;
}

/* event */

.events {
  width: 100vw;
  background: rgba(0, 0, 0, 0.07);
  margin: 85px 0;
  margin-bottom: 125px;
  display: flex;
  justify-content: center;
  padding: 65px 0;
}

.events .banner {
  width: calc(100vw - 200px);
  background: white;
  border-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 35px 75px;
  padding-top: 40px;
}

.events .banner > h2 {
  font-size: 16px;
  margin-top: 55px;
  color: #2865be;
  font-weight: 800;
}

.events .banner hr {
  border: none;
  outline: none;
  height: 1px;
  background: black;
  margin: 25px 0;
  margin-top: 50px;
}

.events .banner p {
  font-size: 17px;
  font-weight: 200;
}

.events .steps {
  height: 50%;
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  padding-top: 30px;
  padding-bottom: 30px;
  justify-content: space-between;
  color: white;
}

.events .step {
  width: 49%;
  height: 200px;
  display: flex;
  justify-content: space-between;
  gap: 50px;
  background: #94aac9;
  padding: 20px;
  border-radius: 20px;
}

.events .step:nth-child(odd) {
  flex-direction: row-reverse;
}

.events .step .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.events .step:nth-child(odd) .content {
  align-items: end;
}

.events .step:nth-child(odd) p {
  text-align: end;
}

.events .step img {
  border-radius: 20px;
}

.events .step h2 {
  width: fit-content;
  margin: 0;
  margin-top: 3px;
  font-weight: 500;
  font-size: 24px !important;
}

/* doctors */

.doctors {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.doctors .sub_heading {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 800;
  color: #417ed7;
}

.doctors h1 {
  margin-top: 15px;
  font-weight: 500;
  font-size: 44px;
  text-align: center;
}

.doctors .list {
  height: 375 px;
  margin-top: 45px;
  display: flex;
  justify-content: center;
  gap: 50px;
}

.doctors .doctor {
  width: 325px;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.doctors .doctor img {
  width: 100%;
  border-radius: 20px;
  border: 2px solid rgba(0, 0, 0, 0.24);
}

.doctors .doctor .name {
  font-size: 20px;
  font-weight: 700;
  margin-top: 25px;
  margin-bottom: 9px;
}

.doctors .doctor .designation {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 300;
  margin-top: 3px;
}

.doctors .doctor button {
  width: fit-content;
  left: 50%;
  bottom: 140px;
  transform: translateX(-50%);
  position: relative;
  border: 1px solid #f82828;
  color: #f82828;
  background: white;
  font-size: 16px;
  padding: 11px 15px;
  cursor: pointer;
  border-radius: 25px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  transition: 0.5s;
}

.doctors .doctor:first-of-type button {
  bottom: 160px;
}

.doctors .doctor button i {
  font-size: 20px;
  background: #f82828;
  color: white;
  border-radius: 50%;
  margin-right: 5px;
  transition: 0.5s;
}

.doctors .doctor button:hover {
  background: #f82828;
  color: white;
}

.doctors .doctor button:hover i {
  color: #f82828;
  background: white;
}

a {
  text-decoration: none !important;
}

@media only screen and (max-width: 1350px) {
  .home .hero {
    width: 100vw;
    height: 750px;
    padding: 0 25px;
  }

  .hero .box.a {
    background: #d6cdd6;
    grid-column: 1 / 4;
    grid-row: 1 / 10;
    margin: 3% 0;
    padding: 0 25px;
  }

  .hero .box.a h1 {
    font-size: 32px;
    font-weight: 400;
    margin: 0;
  }

  .hero .box.b {
    justify-content: start;
    padding-top: 55px;
  }

  .hero .box.b h1 {
    color: white;
    font-size: 44px;
    font-weight: 900;
    margin-top: 5px;
    text-align: start;
  }

  .hero .box.b ul {
    gap: 0;
    padding-top: 0px;
    margin-top: 15px;
  }

  .hero .box.b li {
    line-height: 20px;
  }

  .hero .box.d {
    grid-row: 10 / 22;
  }

  .hero .box.d img {
    width: 99%;
  }

  .hero .box.e {
    grid-row: 10 / 22;
    padding: 10px;
  }

  .hero .box.e img {
    width: 100%;
    height: 70%;
  }

  .hero .box.e p {
    font-size: 14px;
  }

  .hero .box.e span {
    padding: 5px;
    font-size: 12px;
  }

  .hero .box.e span i {
    padding: 5px;
    font-size: 16px;
    margin-right: 5px;
  }

  .hero .box.e span a {
    letter-spacing: 1px;
    text-decoration: none;
    padding-right: 15px;
    font-weight: 800;
    padding-left: 2px;
    color: #51aaa2;
  }

  .hero .box.f {
    grid-row: 10 / 22;
  }

  .hero .box.f .content {
    width: 315px;
    height: calc(100% - 120px);
    padding: 10px 20px;
  }

  .hero .box.f span {
    margin-bottom: 15px;
  }

  .hero .box.f button {
    margin-top: 15px;
  }

  .hero .box.f button i {
    color: #998099;
    background: white;
    border-radius: 50%;
    margin-right: 11px;
    font-size: 20px;
    transition: 0.5s;
  }

  .hero .box.f button:hover {
    background: #2865be;
  }

  .hero .box.f button:hover i {
    color: #2865be;
  }

  .partner_logos {
    width: 100vw;
    padding: 100px 50px;
    padding-top: 145px;
  }

  .home .message {
    width: 1100px;
    padding-top: 25px;
  }

  .home .message > span {
    width: 100%;
    font-size: 16px;
  }

  .home .message p {
    width: 100%;
    font-size: 52px;
    margin: 0;
    margin-top: 15px;
  }

  .home .message img {
    display: none;
  }

  .home .experiences {
    width: 100vw;
    padding: 0 25px;
    margin-top: 55px;
  }

  .home .experiences .item.a .content {
    display: flex;
    flex-direction: column;
    padding-top: 15px;
  }

  .home .experiences .item.a .content h1 {
    font-size: 34px;
    margin-bottom: 5px;
  }

  .home .experiences .item.a .content li {
    font-size: 16px;
  }

  .scrolling_text {
    margin-bottom: 80px;
  }

  .tests .doctor .doctor_award {
    margin-right: 50px;
  }

  .home .our_services {
    width: calc(100vw - 100px);
  }

  .tests {
    width: calc(100vw - 50px);
  }

  .tests > .content {
    padding: 85px 40px;
  }

  .tests .tests_slider .item {
    min-height: fit-content;
    padding: 35px 30px;
  }

  .tests .tests_slider p {
    width: 100%;
    display: none;
  }

  .tests .tests_slider img {
    display: none;
  }

  .tests .tests_slider button {
    margin-left: 0;
    margin-top: 15px;
  }

  .events {
    margin-bottom: 225px;
  }

  .events .banner {
    width: calc(100% - 50px);
  }

  .events .banner .step {
    width: 48%;
  }

  .events .banner .content > h2 {
    font-size: 32px;
  }

  .events .banner .content span {
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .home .hero {
    width: 970px;
    height: 1200px;
    padding: 0;
  }

  .hero .box.a {
    grid-row: 1 / 10;
    margin: 0;
    padding: 25px;
  }

  .hero .box.a h1 {
    font-size: 28px;
    margin: 0;
  }

  .hero .box.a p {
    margin: 5px 0;
    font-size: 14px;
    line-height: 23px;
  }

  .hero .box.b {
    grid-row: 1 / 10;
    padding: 25px 35px;
  }

  .hero .box.b h1 {
    font-size: 16px;
  }

  .hero .box.b ul {
    padding-top: 0px;
  }

  .hero .box.b ul li {
    padding-right: 0;
    font-size: 14px;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .hero .box.b ul li i {
    padding-top: 6px;
  }

  .hero .box.b button {
    display: none;
  }

  .hero .box.c {
    grid-row: 1 / 10;
    padding: 15px;
    gap: 0;
  }

  .hero .box.c h1 {
    display: none;
  }

  .hero .box.c img {
    width: 100%;
    margin: 0;
  }

  .hero .box.c img:last-of-type {
    margin-top: 10px;
  }

  .hero .box.d {
    grid-row: 10 / 24;
    grid-column: 1 / 5;
  }

  .hero .box.e {
    grid-column: 5 / 15;
    grid-row: 10 / 24;
    padding: 75px;
  }

  .hero .box.e img {
    width: 100%;
    height: 70%;
    padding-bottom: 0;
  }

  .hero .box.e p {
    font-size: 18px;
    padding: 0 10px;
    margin-top: 0;
  }

  .hero .box.e span {
    padding: 5px;
    font-size: 20px;
  }

  .hero .box.e span i {
    padding: 9px;
    font-size: 24px;
    margin-right: 12px;
  }

  .hero .box.f {
    grid-column: 1 / 15;
    grid-row: 24 / 30;
    height: 525px;
  }

  .hero .box.f .content {
    width: 285px;
    height: fit-content;
    padding: 10px 20px;
    padding-bottom: 18px;
  }

  .hero .box.f span {
    margin-bottom: 15px;
  }

  .partner_logos {
    width: 100vw;
    padding: 100px 75px;
    padding-top: 150px;
  }

  .partner_logos .partner {
    width: 75px;
  }

  .home .message {
    width: 970px;
    padding-top: 25px;
  }

  .home .experiences {
    width: 970px;
    padding: 0;
    margin-top: 50px;
  }

  .our_services h1 {
    width: 75%;
  }

  .our_services .services {
    height: fit-content;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }

  .our_services .services .service {
    width: 970px;
    padding-bottom: 65px;
  }

  .tests {
    width: 970px;
  }

  .tests > .content h2 {
    font-size: 32px;
  }

  .tests .tests_slider .item {
    height: 100%;
  }

  .tests .doctor .doctor_award > i:last-of-type {
    display: none;
  }

  .events {
    margin-bottom: 225px;
  }

  .events .banner {
    width: 970px;
    padding: 0 50px;
  }

  .events .banner .steps {
    height: 100%;
    height: fit-content;
    flex-wrap: nowrap;
    flex-direction: column;
    gap: 50px;
  }

  .events .banner .step {
    width: 100%;
  }

  .events .banner .step:nth-child(odd) .content {
    align-items: start;
  }

  .events .banner .step:nth-child(odd) p {
    text-align: start;
  }

  .events .banner h2 {
    width: 75%;
  }
}

@media only screen and (max-width: 990px) {
  .home .hero {
    width: 693px;
    height: 1850px;
    padding: 0;
  }

  .hero .box.a {
    grid-column: 1 / 15;
    grid-row: 1 / 12;
    padding: 25px 40px;
  }

  .hero .box.a h1 {
    width: 75%;
    font-size: 34px;
  }

  .hero .box.a p {
    margin: 5px 0;
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 23px;
  }

  .hero .box.b {
    grid-column: 1 / 15;
    grid-row: 12 / 21;
  }

  .hero .box.c {
    grid-column: 1 / 11;
    grid-row: 21 / 27;
    flex-direction: row;
  }

  .hero .box.c img {
    width: 50%;
    height: fit-content;
    padding: 7%;
    padding-bottom: 0;
  }

  .hero .box.d {
    grid-column: 1 / 6;
    grid-row: 27 / 36;
  }

  .hero .box.e {
    grid-column: 6 / 15;
    grid-row: 27 / 36;
    padding: 35px 0;
  }

  .hero .box.f {
    grid-column: 1 / 15;
    grid-row: 36 / 41;
    height: 350px;
  }

  .hero .box.c img.string_shape {
    display: none;
  }

  .hero .box.e img {
    width: 100%;
    height: 70%;
    padding-bottom: 0;
  }

  .hero .box.e p {
    font-size: 14px;
    padding: 0 15px;
    padding: 0 10px;
    margin-top: 0;
  }

  .hero .box.e span {
    font-size: 14px;
  }

  .partner_logos {
    width: 693px;
    padding: 100px 0;
    padding-top: 145px;
    gap: 50px;
  }

  .home .message {
    width: 693px;
    padding-top: 25px;
  }

  .home .experiences {
    width: 693px;
    height: fit-content;
    padding: 0;
    margin-top: 0;
  }

  .home .experiences .item {
    width: 100%;
  }

  .our_services {
    width: 693px;
  }

  .our_services h1 {
    width: 75%;
  }

  .our_services .services {
    width: 693px;
    height: fit-content;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }

  .our_services .services .service {
    width: 693px;
  }

  .tests {
    width: 693px;
    height: 1350px;
    flex-direction: column;
    align-items: center;
    margin-top: 85px;
  }

  .tests .doctor {
    width: 100%;
    height: 100%;
  }

  .tests > .content {
    width: 100%;
    height: 45%;
  }

  .events {
    margin-bottom: 175px;
  }

  .events .banner {
    width: 693px;
    height: fit-content;
    padding: 0 50px;
    flex-direction: column;
    align-items: start;
  }

  .events .banner h2 {
    width: 75%;
  }

  .events .banner .steps {
    gap: 20px;
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 753px) {
  .home .hero {
    width: 532px;
    height: fit-content;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  .hero .box.a {
    padding: 15px;
    height: fit-content;
    padding: 35px;
  }

  .hero .box.b {
    height: 375px;
    padding: 15px;
  }

  .hero .box.c {
    width: 90%;
    height: fit-content;
    overflow: hidden;
  }

  .hero .box.d {
    height: 500px;
  }

  .hero .box.e {
    height: fit-content;
  }

  .hero .box.e .content {
    height: fit-content;
  }

  .hero .box.f {
    height: 300px;
    padding: 15px;
    gap: 15px;
  }

  .hero .box.f img {
    width: 100%;
  }

  .partner_logos {
    width: 532px;
    flex-wrap: wrap;
    row-gap: 55px;
    padding: 85px 25px;
    padding-top: 150px;
  }

  .partner_logos span {
    top: 80px;
  }

  .partner_logos .partner {
    width: 65px;
  }

  .home .message {
    width: 532px;
  }

  .home .message > span {
    font-size: 14px;
  }

  .home .message p {
    font-size: 28px;
  }

  .home .experiences {
    width: 532px;
    height: fit-content;
    margin-top: 75px;
  }

  .home .experiences .item.a {
    height: 315px;
    padding-top: 0;
  }

  .home .experiences .item.b {
    height: fit-content;
  }

  .home .experiences .item.d {
    height: 300px;
  }

  .home .experiences .item.e {
    height: fit-content;
    flex-direction: column;
    align-items: start;
    padding: 20px 35px;
    padding-top: 43px;
  }

  .home .scrolling_text {
    margin: 35px 0;
  }

  .home .scrolling_text .text {
    font-size: 54px;
    margin: 0;
  }

  .our_services {
    margin-top: 15px;
  }

  .our_services .sub_heading {
    font-size: 12px;
  }

  .our_services h1 {
    font-size: 28px;
  }

  .our_services .services .service {
    width: 532px;
  }

  .tests {
    width: 532px;
    height: 950px;
    margin-top: 45px;
  }

  .tests .content {
    padding: 35px 30px;
    height: fit-content;
  }

  .tests .content .sub_heading {
    font-size: 12px;
  }

  .tests .content h2 {
    font-size: 28px;
  }

  .tests .tests_slider {
    margin-top: 35px;
  }

  .tests .tests_slider .item {
    padding: 30px 15px;
  }

  .events .banner {
    width: 532px;
    padding: 20px;
  }

  .events .banner span {
    font-size: 12px;
  }

  .events .banner h2 {
    font-size: 28px;
    padding-top: 0px;
    margin-top: 10px;
  }

  .events .banner hr {
    margin-top: 30px;
  }

  .events .banner .steps {
    padding-top: 10px;
  }

  .events .banner .step {
    gap: 15px;
  }

  .events .banner .step .left,
  .events .banner .step .right {
    width: 50%;
  }

  .events .banner .step p {
    font-size: 14px;
  }

  .doctors .sub_heading {
    font-size: 12px;
  }

  .doctors h1 {
    font-size: 28px;
    margin-top: 5px;
  }

  .doctors .list {
    width: 100%;
    height: fit-content;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
    gap: 5px;
  }
}

@media only screen and (max-width: 556px) {
  .home .hero {
    width: 100vw;
    padding: 0 12px;
  }

  .hero .box.a {
    padding: 20px 15px;
  }

  .hero .box.b {
    height: 350px;
    padding: 15px;
    padding-bottom: 50px;
  }

  .hero .box.b h1 {
    margin-top: 15px;
    margin-left: 35px;
  }

  .hero .box.c {
    width: 100%;
    height: fit-content;
    flex-direction: column;
  }

  .hero .box.c img {
    width: 100%;
    padding: 15%;
    padding-bottom: 0;
  }

  .hero .box.e {
    height: fit-content;
    padding: 35px 0;
  }

  .hero .box.e .content {
    height: fit-content;
  }

  .hero .box.f {
    height: 250px;
    gap: 10px;
    padding: 10px;
  }

  .hero .box.f .content {
    display: none;
  }

  .hero .box.f img {
    width: 100%;
  }

  .partner_logos {
    width: 100vw;
    row-gap: 55px;
    padding: 85px 13px;
    padding-top: 125px;
  }

  .home .message {
    width: 100vw;
    padding: 0 15px;
  }

  .home .experiences {
    width: 100vw;
    padding: 0 15px;
    margin-top: 35px;
  }

  .home .experiences .item.a {
    height: 325px;
    padding-top: 0;
  }

  .home .experiences .item.a h1 {
    padding-top: 0;
  }

  .home .experiences .item.b {
    height: fit-content;
  }

  .home .experiences .item.b .identity {
    margin-top: 35px;
  }

  .home .experiences .item.d {
    height: 240px;
  }

  .home .experiences .item.e {
    width: calc(100vw - 30px);
  }

  .home .experiences .item.e {
    max-width: 100vw;
  }

  .our_services h1 {
    margin-top: 7px;
    width: 100%;
  }

  .our_services .services .service {
    width: calc(100vw - 30px);
  }

  .tests {
    width: calc(100vw - 30px);
    height: 925px;
    margin-top: 45px;
  }

  .events {
    margin-bottom: 165px;
  }

  .events .banner {
    width: calc(100vw - 30px);
    padding: 20px;
  }

  .events .banner .steps .step {
    gap: 25px;
    height: fit-content;
    flex-direction: column;
  }

  .doctors {
    width: calc(100vw - 30px);
  }

  .home .experiences .item.b p {
    height: 115px;
  }
}

#root > h1,
#root > h2 {
  height: 100dvh !important;
  text-align: center;
  padding-top: 35vh;
  color: #97b1ab;
}

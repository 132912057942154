@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap");

body {
  width: 100vw;
  min-height: 100vh;
  max-height: fit-content;
  position: relative;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
}

* {
  font-family: "Public Sans", sans-serif;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  scroll-behavior: smooth;
}

.admin {
  width: 100vw;
  min-height: 750px;
  padding: 25px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin button {
  position: relative;
  font-size: 20px;
  padding: 5px 12px;
}

.admin button input {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  opacity: 0;
}

.admin .gallery_images {
  width: calc(100vw - 200px);
  padding: 25px 0;
  padding-top: 25px !important;
  padding-bottom: 25px !important;
  display: flex;
  gap: 35px;
  row-gap: 50px;
  flex-wrap: wrap;
  overflow: hidden;
}

.admin .gallery_images .image {
  width: calc(25% - 27px);
  position: relative;
}

.admin .gallery_images img {
  width: 100%;
  border-radius: 15px;
}

.admin .gallery_images .image button {
  width: fit-content;
  height: fit-content;
  position: absolute;
  inset: 15px;
  border-radius: 7px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  font-size: 24px;
  color: rgba(0, 0, 0, 0.7);
  padding: 3px 7px;
}

@media only screen and (max-width: 1350px) {
  .admin .gallery_images {
    width: 100vw;
    padding: 0 25px;
  }

  .admin .gallery_images .image {
    width: calc(33% - 27px);
  }
}

@media only screen and (max-width: 1200px) {
  .admin .gallery_images {
    width: 970px;
    padding: 0;
  }

  .admin .gallery_images .image {
    width: calc(50% - 27px);
  }
}

@media only screen and (max-width: 990px) {
  .admin .gallery_images {
    width: 693px;
    padding: 0;
  }
}

@media only screen and (max-width: 753px) {
  .admin .gallery_images {
    width: 532px;
    padding: 0;
    row-gap: 25px;
    align-items: center;
    justify-content: center;
  }

  .admin .gallery_images .image {
    width: 100%;
  }

  .admin .gallery_images .image button {
    inset: 25px 35px;
  }
}

@media only screen and (max-width: 553px) {
  .admin .gallery_images {
    width: 100vw;
    padding: 0 12px;
  }

  .admin .gallery_images .image {
    width: 100vw;
    padding: 0;
  }
}

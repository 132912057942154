.main_gallery {
  width: 100vw;
  min-height: 100vh;
  margin-bottom: 50px;
  padding: 25px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.main_gallery .gallery_images {
  width: calc(100vw - 200px);
  padding: 25px 0;
  padding-top: 25px !important;
  padding-bottom: 25px !important;
  display: flex;
  gap: 35px;
  row-gap: 50px;
  flex-wrap: wrap;
  overflow: hidden;
}

.main_gallery img {
  width: fit-content;
  height: fit-content;
  width: calc(25% - 27px);
  position: relative;
  object-fit: contain;
  border-radius: 15px;
}

.main_gallery .gallery_slider {
  width: calc(100vw - 200px);
  height: calc(100vh - 200px);
  position: absolute;
  z-index: 1;
  inset: 50%;
  padding: 0;
  display: none;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  border-radius: 15px;
  overflow: hidden;
  transform: translate(-50%, -60%);
}

.main_gallery .gallery_slider.active {
  display: flex;
}

.main_gallery .gallery_slider img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  object-fit: contain;
  object-position: center;
  background: white;
}

.main_gallery .gallery_slider .left_btn,
.main_gallery .gallery_slider .right_btn {
  width: 40%;
  height: 100%;
  position: absolute;
  z-index: 1;
  transition: 0.4s;
  border-radius: 35%;
}

.main_gallery .gallery_slider .left_btn:active,
.main_gallery .gallery_slider .right_btn:active {
  background: rgba(0, 0, 0, 0.04);
}

.main_gallery .gallery_slider .left_btn {
  left: -50px;
}

.main_gallery .gallery_slider .right_btn {
  right: -50px;
}

.main_gallery .gallery_slider .close_btn {
  width: fit-content;
  height: fit-content;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 1;
  font-size: 100px;
  transform: translate(-50%, 0);
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.18);
}

.main_gallery .gallery_slider .close_btn:active {
  background: rgba(0, 0, 0, 0.04);
}

.main_gallery .gallery_slider i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 500px;
  transition: 0.4s;
  color: rgba(0, 0, 0, 0.05);
}

.main_gallery .gallery_slider .left_btn:active i,
.main_gallery .gallery_slider .right_btn:active i,
.main_gallery .gallery_slider .close_btn:active {
  color: rgba(0, 0, 0, 0.2);
}

.main_gallery .gallery_slider i.bx-chevron-left {
  left: 50px;
}

.main_gallery .gallery_slider i.bx-chevron-right {
  right: 50px;
}

@media only screen and (max-width: 1350px) {
  .main_gallery .gallery_images {
    width: 100vw;
    padding: 0 25px;
  }

  .main_gallery .gallery_images img {
    width: calc(33% - 27px);
  }

  .main_gallery .gallery_slider {
    width: calc(100vw - 50px);
  }
}

@media only screen and (max-width: 1200px) {
  .main_gallery .gallery_images {
    width: 970px;
    padding: 0;
  }

  .main_gallery .gallery_images img {
    width: calc(50% - 27px);
  }

  .main_gallery .gallery_slider {
    width: 970px;
    top: 50px;
    transform: translate(-50%, 0%);
  }
}

@media only screen and (max-width: 990px) {
  .main_gallery .gallery_images {
    width: 693px;
    padding: 0;
  }

  .main_gallery .gallery_slider {
    width: 693px;
    top: 50px;
  }

  .main_gallery .gallery_slider i {
    font-size: 300px;
  }
}

@media only screen and (max-width: 753px) {
  .main_gallery .gallery_images {
    width: 532px;
    padding: 0;
    row-gap: 25px;
    align-items: center;
    justify-content: center;
  }

  .main_gallery .gallery_images img {
    width: 532px;
  }

  .main_gallery .gallery_slider {
    width: 532px;
    top: 50px;
  }

  .main_gallery .gallery_slider i {
    font-size: 100px;
  }
}

@media only screen and (max-width: 553px) {
  .main_gallery .gallery_images {
    width: 100vw;
    padding: 0 12px;
  }

  .main_gallery .gallery_images img {
    width: calc(100vw - 25px);
  }

  .main_gallery .gallery_slider {
    width: calc(100vw - 25px);
    top: 40px;
  }

  .main_gallery .gallery_slider i {
    font-size: 100px;
  }

  .main_gallery .gallery_slider .left_btn {
    left: 0px;
  }

  .main_gallery .gallery_slider .right_btn {
    right: 0px;
  }
}

nav {
  width: (100vw - 100px);
}

.nav_wall {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.17);
  position: absolute;
  inset: 0;
  display: none;
  z-index: 2;
}

.nav_wall.active {
  display: block;
}

.nav1 {
  width: 350px;
  min-height: calc(100dvh - 50px);
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 0;
  overflow: scroll;
  overflow-x: hidden;
  position: fixed;
  right: -350px;
  top: 25px;
  z-index: 4;
  background: white;
  transition: 0.33s ease-out;
  transition-delay: 0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 25px;
}

.nav1.active {
  right: 25px;
}

.nav1 .wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  z-index: 2;
  padding: 35px 0;
  align-items: start;
  justify-content: start;
  flex-direction: column;
}

.nav1 .left {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
}

.nav1 .wrapper .logo {
  height: 65px;
  position: relative;
  margin-left: 120px;
  background: white;
}

.nav1 .wrapper img {
  height: 100%;
}

.nav1 ul {
  width: 100%;
  height: 100%;
  display: flex;
  list-style: none;
  flex-direction: column;
  gap: 15px;
  margin: 0;
  margin-top: 35px;
  color: grey;
  padding: 0 25px;
  padding-right: 68px;
  position: relative;
  padding-bottom: 50px;
}

.nav1 ul li {
  width: 290px;
}

.nav1 ul .link {
  width: 100%;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  transition: 0.3s;
  display: flex;
  padding: 17px 54px;
  text-align: start;
  color: rgba(0, 0, 0, 0.6);
  position: relative;
  border-radius: 10px;
  display: inline-block;
}

.nav1 ul .link.active,
.nav1 ul .link:active {
  background: rgba(122, 199, 185, 0.3);
  color: #294747;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.nav1 ul .link i,
.nav1 ul .link img {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-60%);
  font-size: 22px;
}

.nav1 ul .link img {
  width: 20x;
  padding: 0;
  margin-right: 0;
  left: 5px;
  transform: translateY(-45%);
}

.nav1 button {
  width: calc(100% - 55px);
  height: fit-content;
  padding: 17px 15px;
  border-radius: 15px;
  background: #7ac7b9;
  color: white;
  cursor: pointer;
  font-size: 18px;
  border: 2px solid #7ac7b9;
  transition: 0.3s;
  font-weight: 700;
  position: relative;
  margin: 0;
  left: 25px;
  bottom: 25px;
  margin-top: auto;
}

.nav1 button:hover,
.nav1 button:active {
  background: white;
  color: #7ac7b9;
}

.nav0 {
  width: calc(100vw - 200px);
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  margin-top: 25px;
  transition: 0.3s;
  padding: 0;
  margin-left: 100px;
}

.nav0 img.logo {
  width: 275px;
  cursor: pointer;
}

.ham {
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  cursor: pointer;
  transition: 0.5s;
  transition-delay: 0s;
  padding: 0 17px;
  border-radius: 50%;
  position: absolute;
  right: 100px;
  top: 35px;
}

nav .ham .bar {
  width: 40%;
}

nav .ham .bar,
nav .ham .bar::before,
nav .ham .bar::after {
  height: 4px;
  background: rgb(76, 76, 76);
  position: relative;
  border-radius: 15px;
  transition: 0.3s;
}

nav .ham .bar::before,
nav .ham .bar::after {
  content: "";
  width: 200%;
  position: absolute;
  top: 10px;
}

nav .ham .bar::before {
  width: 140%;
  top: -10px;
}

nav .ham.active {
  position: fixed;
  top: 60px;
  right: 290px;
  transition: 0.46s;
  transition-delay: 0.05s;
}

nav .ham.active .bar,
nav .ham.active .bar::before {
  width: 100%;
  transform: rotate(90deg);
  top: -10px;
  left: -10px;
}

nav .ham.active .bar {
  width: 75%;
  transform: rotate(45deg);
  top: 10px;
}

nav .ham.active .bar::after {
  display: none;
  transform: rotate(45deg);
  top: 14px;
  transition-delay: 0.5s;
}

@media only screen and (max-width: 1350px) {
  .nav0 {
    width: fit-content;
    min-height: 87px;
    margin: 0;
    margin-top: 25px;
    margin-left: 25px;
    overflow: hidden;
  }

  .nav_wall {
    top: 0px;
  }

  nav .ham.active {
    position: fixed;
    right: 285px;
    top: 60px;
    transition: 0.5s;
  }

  nav .ham {
    position: absolute;
    right: 50px;
    top: 35px;
  }
}

@media only screen and (max-width: 1200px) {
  .nav0 {
    width: 973px;
    margin-left: 0;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  nav .ham.active {
    right: 290px;
    top: 55px;
    transition: 0.5s;
    transform: none;
  }

  nav .ham {
    right: calc(50% - 900px / 2);
    top: 50px;
    z-index: 5;
    transform: translateX(50%);
  }
}

@media only screen and (max-width: 990px) {
  .nav0 {
    width: 694px;
    min-height: 63px;
    margin-left: 0;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  nav .ham.active {
    right: 285px;
    top: 60px;
    transition: 0.5s;
  }

  nav .ham {
    position: absolute;
    right: calc(50% - 600px / 2);
    transform: translateX(50%);
    top: 40px;
  }
}

@media only screen and (max-width: 756px) {
  .nav0 img.logo {
    width: 200px;
  }

  .nav0 {
    width: 532px;
    padding-left: 35px;
  }

  nav .ham {
    right: calc(50% - 465px / 2);
    transform: translateX(50%);
    top: 30px;
  }

  nav .ham.active {
    top: 50px;
  }

  .nav1 .wrapper {
    padding-top: 25px;
  }
}

@media only screen and (max-width: 556px) {
  .nav1 {
    width: calc(100vw - 20px);
    height: calc(100dvh - 20px);
    right: -100vw;
    top: 10px;
  }

  .nav1.active {
    right: 10px;
  }

  .nav1 .wrapper .logo {
    margin: 0;
    margin-top: 15px;
    margin-left: 35px;
  }

  .nav1 ul li {
    width: calc(100vw - 76px);
  }

  .nav1 ul .link {
    width: 100% !important;
    padding-right: 0;
  }

  .nav0 {
    width: 100vw;
    padding: 0 15px;
    margin-top: 25px;
  }

  .nav0 img {
    transition: 0.3s;
    transition-delay: 0.3s;
    opacity: 1;
  }

  .nav0 img.inactive {
    opacity: 0;
    transition-delay: 0s;
  }

  nav .ham {
    top: 31px;
    right: 50px;
  }

  nav .ham.active {
    right: 28px;
    top: 50px;
  }

  .nav1 ul .link {
    width: calc(100vw - 95px);
  }
}

@media only screen and (max-width: 426px) {
  .nav0 img.logo {
    width: 175px;
  }
}
